<template>
  <section class="m-address-edit m-scroll">
    <!-- 头部导航 -->
    <div class="header">Delivery information</div>

    <van-form @submit="onSubmit" class="form">
      <van-field
        v-model="surname"
        name="surname"
        label="Surname"
        @input="surname = surname.replace(/[\d]/g, '')"
        :rules="[{ required: true, pattern: /^[a-zA-Z]*$/, message: 'Error, English words only' }]"
      >
      </van-field>
      <van-field
        v-model="name"
        name="name"
        label="Name"
        @input="surname = surname.replace(/[\d]/g, '')"
        :rules="[{ required: true, message: 'Error, Can`t be empty' }]"
      />
      <van-field
        v-model="phone"
        name="phone"
        label="Phone number"
        :rules="[{ required: true, message: 'Error, Can`t be empty' }]"
      />
      <van-field
        v-model="email"
        name="email"
        label="Email address"
        :rules="[{ required: true, message: 'Error, Email format error', pattern: /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/ }]"
      />
      <van-field
        readonly
        clickable
        name="city"
        :value="cityText"
        label="Select Area"
        :rules="[{ required: true, message: 'Error, Can`t be empty' }]"
        @click="showPicker = true"
      />
      <van-field
        v-if="cityShow"
        v-model="city_name"
        autosize
        name="city_name"
        label="City"
        placeholder=""
        :rules="[{ required: true, message: 'Error, Can`t be empty' }]"
      />
      <van-field
        v-model="address"
        rows="2"
        label-width="6.4em"
        autosize
        name="address"
        label="Detailed address"
        type="textarea"
        placeholder="*If you live in an apartment, please fill in unit# and/or buzz code."
        :rules="[{ required: true, message: 'Error, Can`t be empty' }]"
      />
      <van-field 
      v-model="code"
      name="code" 
      label="Postal code" 
      :rules="[{ required: true, message: 'Please enter the correct postcode', pattern: /^[A-Za-z0-9]+$/ }]"
   />

      <div class="footer">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          color="linear-gradient(90deg, #2D77E0, #245FB3)"
          >Done</van-button
        >
      </div>
    </van-form>

    <!-- 选择地址 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        title="Select territory"
        confirm-button-text="Done"
        cancel-button-text="Cancel"
        value-key="ename"
        :columns="columns"
        @confirm="onConfirm"
        @change="change"
        @cancel="showPicker = false"
      />
    </van-popup>
  </section>
</template>

<script>
import { city, address_add, address_put } from "@/api/en/mine";

export default {
  name: "AddressEdit",
  data() {
    return {
      id: "",
      name: "",
      surname: "",
      phone: "",
      email: "",
      city: [],
      cityShow: false,
      city_name: '',
      cityText: "",
      address: "",
      code: "",
      columns: [],
      showPicker: false,
    };
  },

  created() {
    this.getCity();
  },

  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.title = "修改地址";
      let addr = JSON.parse(window.sessionStorage.getItem("address"));
      this.surname = addr.surname;
      this.name = addr.name;
      this.phone = addr.phone;
      this.email = addr.email;
      this.address = addr.addr;
      this.code = addr.code;
      this.cityText =
        addr.country_arr.ename +
        "-" +
        addr.province_arr.ename +
        (addr.city_arr ?  '-' + addr.city_arr.ename : '')
      this.city_name = addr.city_name
      this.city = [addr.country, addr.province, addr.city];
      if(addr.country == 1) {
        this.cityShow = false
      } else {
        this.cityShow = true
      }
    } else {
      this.id = "";
      // this.title = '添加地址'
    }
  },

  methods: {
    // 获取城市列表
    getCity() {
      city().then((res) => {
        if (res) {
          this.columns = res.data;
          this.columns.forEach((e) => {
            e.children.forEach((el) => {
              if(e.id == 507) {
                delete el.children
              } else {
                el.children.forEach(ele => {
                  delete ele.children
                })
              }
            });
          });
        }
      });
    },
    change(picker, values) {
      console.log(picker)
      if(values[0] == 'America') {
        document.getElementsByClassName('van-picker__columns')[0].children[2].style.display = 'none'
      } else {
        document.getElementsByClassName('van-picker__columns')[0].children[2].style.display = 'block'
      }
    },
    // 取人添加
    onSubmit() {
      let params = {
        surname: this.surname,
        name: this.name,
        phone: this.phone,
        email: this.email,
        country: this.city[0],
        province: this.city[1],
        city_name: this.city[0] == 1 ? '' : this.city_name,
        city    : this.city[0] == 1 ? this.city[2] : 0,
        addr: this.address,
        code: this.code,
      };
      if (this.id != "") {
        address_put(this.id, params).then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              duration: 2000,
              message: "Modified address successfully",
            });
            this.$router.go(-1);
          }
        });
      } else {
        address_add(params).then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              duration: 2000,
              message: "Add address successfully",
            });
            this.$router.go(-1);
          }
        });
      }
    },
    // 选择地址 popup 确定按钮
    onConfirm(value, ids) {
      if(this.columns[ids[0]].id == 507) {
        this.cityShow = true
        value.pop()
      } else {
        this.cityShow = false
      }
      this.cityText = value.join("-");
      this.city = [];
      if (ids.length == 3) {
        this.city.push(this.columns[ids[0]].id);
        this.city.push(this.columns[ids[0]].children[ids[1]].id);
        if(this.columns[ids[0]].id == 1) {
          this.city.push(this.columns[ids[0]].children[ids[1]].children[ids[2]].id)
        }
      }
      this.showPicker = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./edit.less";
</style>